<template>
  <div>
    <air-login />
  </div>
</template>
<script>
import AirLogin from '@/components/system/Auth/Login'
export default {
  components: {
    AirLogin,
  },
}
</script>
